<template>
    <main>
        <ButtonGroup shape="circle">
            <Button icon="md-refresh" @click="loadData">刷新</Button>
            <!-- <Button icon="md-add-circle" @click="create">添加</Button> -->
        </ButtonGroup>

        <Divider />

        <Table border :columns="columns" :data="data" @on-sort-change="changeSort"></Table>

        <Divider />
        <pagination ref="pagination" :total="total" @on-change="loadData"/>
    </main>
</template>

<script>
    export default {
        data()
        {
            return {
                sort   : '',
                total  : 0,
                data   : [],
                columns: [
                    {
                        title   : 'ID',
                        key     : 'id',
                        sortable: 'custom',
                        minWidth: 80
                    },
                    {
                        title   : 'KEY',
                        key     : 'key',
                        sortable: 'custom',
                        minWidth: 200,
                        render  : (h, params) => {
                            return h('p', { class: 'text-sm text-grey' }, params.row.key)
                        }
                    },
                    {
                        title   : '名称',
                        key     : 'title',
                        sortable: 'custom',
                        minWidth: 300,
                        render  : (h, params) => {
                            return h('div', [
                                        h('p', params.row.label),
                                        h('p', { class: 'text-sm text-grey' }, params.row.desc)
                                    ])
                        }
                    },
                    {
                        title   : '类型',
                        key     : 'type',
                        sortable: 'custom',
                        minWidth: 100,
                        render  : (h, params) => {
                            switch (params.row.type)
                            {
                                case 0:
                                    return h('span', '单图片')

                                case 1:
                                    return h('span', '多图片')

                                case 2:
                                    return h('span', '视频')

                                case 3:
                                    return h('span', '文字')

                                default:
                                    return h('span', '未知')
                            }
                        }
                    },
                    {
                        title   : '状态',
                        minWidth: 90,
                        key     : 'status',
                        sortable: 'custom',
                        render  : (h, params) => {
                            return h('Icon', {
                                props: {
                                    type: params.row.status ? 'ios-checkmark-circle' : 'ios-remove-circle'
                                },
                                class: params.row.status ? 'text-green' : 'text-red'
                            })
                        }
                    },
                    {
                        title : '操作',
                        width : 120,
                        align : 'center',
                        render: (h, params) => {
                            var html = []

                            if (this.Auth([ 'Ad', 'set' ]))
                            {
                                html.push(h('Button', {
                                    props: {
                                        icon : 'ios-create-outline',
                                        shape: 'circle'
                                    },
                                    on: {
                                        click: () => {
                                            this.set(params.row)
                                        }
                                    }
                                }, '设置'))

                                // html.push(h('Button', {
                                //     props: {
                                //         type: 'error',
                                //         size: 'small',
                                //         icon: 'md-trash'
                                //     },
                                //     class: 'mg-lr-xs',
                                //     on: {
                                //         click: () => {
                                //             this.remove(params.row)
                                //         }
                                //     }
                                // }))
                            }

                            return h('div', html)
                        }
                    }
                ]
            }
        },
        mounted()
        {
            this.loadData()
        },
        methods: {
            loadData()
            {
                let params = {
                    sort: this.sort,
                    page      : this.$refs.pagination.page,
                    limit     : this.$refs.pagination.limit
                }
                if (this.$route.params.module !== undefined)
                    params.module = this.$route.params.module

                this.$http.get('/ad/list', { params: params })
                    .then(res => {
                        this.total = res.total
                        this.data  = res.data
                    })
            },
            changeSort(item)
            {
                this.sort = item.order == 'normal' ? '' : item.key + ',' + item.order
                this.loadData()
            },
            create()
            {
                this.$router.push('/ad/create')
            },
            set(item)
            {
                this.$router.push('/ad/set/' + item.id)
            },
            remove(item)
            {
                this.$Modal.confirm({
                    title  : '确定要删除吗？',
                    content: item.key+'-'+item.label,
                    onOk   : () => {
                        this.$http.post('/ad/delete/id/' + item.id)
                            .then(res => {
                                this.loadData()
                            })
                    }
                })
            }
        }
    }
</script>